

import http from "./axios_init";

export default {
    /**
     * 
     * @returns
     */
    get_apps() {
      return http.get("admin/v1/app");
    },
  };
  